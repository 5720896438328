import React from 'react';
import Loading from './loading';

import RensRoute from '../../rensRouter/rensRoute';

import Auth from '../auth/auth';

export default function PrivateRoute({ path, children, authenticated }) {

    const getInternal = () => {
        if (authenticated === 'true') return children;
        if (authenticated === 'false') return <Auth />;
        return <Loading name="website" />
    };

    return (
        <RensRoute path={path}>
            {getInternal()}
        </RensRoute>
    );
}
