import { RensAlertSpawnType, ModalType } from './rensAlert/rensAlert';

const DEFAULT_TRANSITION = '0.3s all ease-out';
const DEFAULT_TRANSFORM = 'translate(-50%, -50%)';

export const NON_TIMED = {
    style: {
        left: '50%',
        top: '15%',
        transform: 'translateX(-50%)',
    },
};

export const DEFAULT_OPTIONS = {
    ...NON_TIMED,
    time: 5000,
};

export const NOTIFY_POPUP = {
    spawn: RensAlertSpawnType.PUSH,
    style: {
        alignSelf: 'flex-start',
        position: 'relative',
        transition: DEFAULT_TRANSITION,
        width: '90%',
        maxWidth: '500px',
        marginLeft: '5px'
    },
    transition: {
        open: {
            style: {
                marginBottom: '5px',
            },
        },
        close: {
            time: 300,
            startAfter: 6500,
            style: {
                transform: 'translateY(-30px)',
                opacity: '0',
            },
        },
    },
};

export const ERROR_POPUP = {
    spawn: RensAlertSpawnType.REPLACE_SAME_TYPE,
    type: ModalType.ERROR_POPUP,
    style: {
        position: 'fixed',
        opacity: 0,
        transition: DEFAULT_TRANSITION,
        left: '50vw',
        top: '30vh',
        transform: DEFAULT_TRANSFORM,
        zIndex: 500000,
    },
    transition: {
        open: {
            style: {
                opacity: 1,
            },
        },
        close: {
            time: 300,
            startAfter: 5000,
            style: {
                opacity: '0',
            },
        },
    },
};

export const OOPS_POPUP = {
    spawn: RensAlertSpawnType.PUSH,
    style: {
        position: 'fixed',
        opacity: 0,
        transition: DEFAULT_TRANSITION,
        left: '50vw',
        top: '30vh',
        transform: DEFAULT_TRANSFORM,
        zIndex: 500000,
    },
    transition: {
        open: {
            style: {
                opacity: 1,
            },
        },
        close: {
            time: 300,
            startAfter: 5000,
            style: {
                opacity: '0',
            },
        },
    },
};

export const STATIC_CENTERED_POPUP = {
    spawn: RensAlertSpawnType.REPLACE_SAME_TYPE,
    style: {
        position: 'fixed',
        opacity: 0,
        top: '30vh',
        left: '50vw',
        transform: DEFAULT_TRANSFORM,
        transition: DEFAULT_TRANSITION,
    },
    transition: {
        open: {
            style: {
                opacity: 1,
            },
        },
    },
};

export const IMAGE_PREVIEW_POPUP = {
    spawn: RensAlertSpawnType.REPLACE_SAME_TYPE,
    style: {
        position: 'absolute',
        opacity: 0,
        top: '50vh',
        left: '50vw',
        transform: DEFAULT_TRANSFORM,
        transition: DEFAULT_TRANSITION,
    },
    transition: {
        open: {
            style: {
                opacity: 1,
            },
        },
    },
};
