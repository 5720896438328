import React, { Component } from 'react';
import autobind from 'class-autobind';

import Util from '../../utils/util';
import Fetch from '../../utils/fetchUtil';
import Storage from '../../utils/storage';

export default class Register extends Component {
    constructor() {
        super();

        this.state = {
            registerName: '',
            registerEmail: '',
            registerPass: '',
            registerPassRepeat: '',
        };

        autobind(this);
    }

    async onSubmitRegister(e) {
        e.preventDefault();

        if (this.state.registerPass === this.state.registerPassRepeat) {
            const s = this.state;
            let ref = null;
            if (Storage.has('ref')) ref = Storage.getString('ref');

            const result = await Fetch.register(s.registerName, s.registerEmail, s.registerPass, ref);
            if (result.ok) {
                Util.updatePopup('Uw account is aangemaakt! Check uw email om uw Account te activeren.');
                this.setState({
                    registerName: '', registerEmail: '', registerPass: '', registerPassRepeat: '',
                });
            } else {
                Util.oopsPopup(result.data.message);
                this.setState({ registerPass: '', registerPassRepeat: '' });
            }
        } else {
            Util.oopsPopup('De ingevulde wachtwoorden komen niet overeen');
            this.setState({ registerPassRepeat: '' });
        }
    }

    render() {
        return (
            <form className="register" onSubmit={this.onSubmitRegister}>
                <h2>Of maak een Account aan</h2>

                <input
                    className="registerName" value={this.state.registerName} type="text" placeholder="Gebruikersnaam" required
                    onChange={(e) => this.setState({ registerName: e.target.value })}
                />
                <input
                    className="registerEmail" value={this.state.registerEmail} type="email"
                    placeholder="Email" required autoComplete="email"
                    onChange={(e) => this.setState({ registerEmail: e.target.value })}
                />
                <input
                    className="registerPass" value={this.state.registerPass} type="password"
                    placeholder="Wachtwoord" required autoComplete="password"
                    onChange={(e) => this.setState({ registerPass: e.target.value })}
                />
                <input
                    className="registerPassRepeat" value={this.state.registerPassRepeat} type="password"
                    placeholder="Herhaal Wachtwoord" required autoComplete="password"
                    onChange={(e) => this.setState({ registerPassRepeat: e.target.value })}
                />

                <input type="submit" value="Aanmelden" className="register" />
            </form>
        );
    }
}
