
import React, { Component, lazy, Suspense } from 'react';
import ReactTooltip from 'react-tooltip';

import Fetch from './utils/fetchUtil';
import Util from './utils/util';
import Storage from './utils/storage';

import Header from './components/header';
import Footer from './components/footer';
import Loading from './components/base/loading';

import RensRoute from './rensRouter/rensRoute';
import PrivateRoute from './components/base/privateRoute';
import RensRouter from './rensRouter/rensRouter';
import RensAlert, { RensAlertContainer } from './rensAlert/rensAlert';

import UserContextProvider from './context/userContextProvider';
import userContext from './context/userContext';

import Auth from './components/auth/auth';
import Verify from './components/auth/verify';
import ChangeEmail from './components/auth/changeEmail';
import State from './components/consultant/state';
import ResetPassword from './components/auth/resetPassword';

import './scss/main.scss';
import './scss/rensalert.scss';

const About = lazy(() => import('./pages/about'));
const Admin = lazy(() => import('./pages/admin'));
const Chat  = lazy(() => import('./pages/chat'));
const ChatHistory  = lazy(() => import('./pages/chat-history'));
const Consultant  = lazy(() => import('./pages/consultant'));
const Credits  = lazy(() => import('./pages/credits'));
const Contact  = lazy(() => import('./pages/contact'));
const EditConsultant = lazy(() => import('./pages/editConsultant'));
const Explanation  = lazy(() => import('./pages/explanation'));
const Home  = lazy(() => import('./pages/home'));
const Order  = lazy(() => import('./pages/order'));
const Orders  = lazy(() => import('./pages/orders'));
const Profile  = lazy(() => import('./pages/profile'));
const Post = lazy(() => import('./pages/post'));
const Questions  = lazy(() => import('./pages/questions'));
const WaitingInviteSent  = lazy(() => import('./pages/waiting-invite-sent'));
const SpiritualCoach = lazy(() => import('./pages/spiritualCoach'));
const SpirtualCenter = lazy(() => import('./pages/spiritualCenter'));
const ClearVoyantOnline = lazy(() => import('./pages/clairvoyantOnline'));

export default class App extends Component {
  constructor() {
    super();

    this.providerRef = React.createRef();
    this.state = {
      loggedIn: 'loading'
    };
  }

  async componentDidMount() {
    const [ loggedIn ] = await Promise.all([
      Fetch.isLoggedIn(),
      userContext.getConsultants()
    ]);

    // Check for referral link
    if (Util.hasQuery('ref')) {
      Storage.store('ref', Util.getQuery('ref'));
    }

    if (loggedIn.ok) {
      await userContext.setup();
      this.providerRef.current.setup();

      this.setState({ loggedIn: 'true' });
    } else {
      this.setState({ loggedIn: 'false' });
      this.providerRef.current.refresh();
    }
  }

  render() {
    return (
      <>
        <RensAlertContainer ref={RensAlert.container} />
        <ReactTooltip class="wendy" />
  
        <RensRouter>
          <div className="app">
              <UserContextProvider ref={this.providerRef}>
                <Header />

                <Suspense fallback={<Loading name="main-loader" />}>
                  <RensRoute path='/'><Home /></RensRoute>
                  <RensRoute path='/consultant/:id'><Consultant /></RensRoute>
                  <RensRoute path='/verify/:id'><Verify /></RensRoute>
                  <RensRoute path='/auth'><Auth /></RensRoute>
                  <RensRoute path='/reset-password/:id'><ResetPassword /></RensRoute>
                  <RensRoute path='/about'><About /></RensRoute>
                  <RensRoute path='/explanation'><Explanation /></RensRoute>
                  <RensRoute path='/contact'><Contact /></RensRoute>
                  <RensRoute path='/spiritual-coach'><SpiritualCoach /></RensRoute>
                  <RensRoute path='/spiritual-center'><SpirtualCenter /></RensRoute>
                  <RensRoute path='/clairvoyant-online'><ClearVoyantOnline /></RensRoute>

                  <PrivateRoute path='/admin' authenticated={this.state.loggedIn}><Admin /></PrivateRoute>
                  <PrivateRoute path='/chat/:id' authenticated={this.state.loggedIn}><Chat /></PrivateRoute>
                  <PrivateRoute path='/chat-history/:id' authenticated={this.state.loggedIn}><ChatHistory /></PrivateRoute> 
                  <PrivateRoute path='/credits' authenticated={this.state.loggedIn}><Credits /></PrivateRoute>
                  <PrivateRoute path='/profile' authenticated={this.state.loggedIn}><Profile /></PrivateRoute>
                  <PrivateRoute path='/order/:id' authenticated={this.state.loggedIn}><Order /></PrivateRoute>
                  <PrivateRoute path='/orders' authenticated={this.state.loggedIn}><Orders /></PrivateRoute>
                  <PrivateRoute path='/change-email/:id' authenticated={this.state.loggedIn}><ChangeEmail /></PrivateRoute>
                  <PrivateRoute path='/edit/consultant' authenticated={this.state.loggedIn}><EditConsultant /></PrivateRoute>
                  <PrivateRoute path='/waiting-invite-sent' authenticated={this.state.loggedIn}><WaitingInviteSent /></PrivateRoute>
                  <PrivateRoute path='/question/:id' authenticated={this.state.loggedIn}><Questions /></PrivateRoute>
                  <PrivateRoute path="/post/:id" authenticated={this.state.loggedIn}><Post /></PrivateRoute>
                </Suspense>

                <State />
                <Footer />
              </UserContextProvider>
          </div>
        </RensRouter>
      </>
    );
  }
}
