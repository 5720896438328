
import React from 'react';

import { Navigate } from '../rensRouter/rensNavigate';

import Logo from '../images/Logo.webp';
import LazyLoadImage from './base/lazyLoadImage';

const logo = () => {
    return (
        <div className="logo" onClick={() => Navigate('/')}>
            <LazyLoadImage src={Logo} width="50px" height="50px" />

            <div className="text">
                <span className="purple">Holistic<span className="green">Chat</span></span>
                <span className="bottom">Mediums en meer..</span>
            </div>
        </div>
    );
}

export default logo;
