import React, { Component } from 'react';
import autobind from 'class-autobind';

import Util from '../../utils/util';

import Fetch from '../../utils/fetchUtil';
import { Context } from '../../context/userContextProvider';

import ForgotPassword from './forgotPassword';
import Storage from '../../utils/storage';

export default class Login extends Component {
    constructor() {
        super();

        let previous = Util.getPage().pathEnd;
        if (previous === 'auth') previous = '/';

        this.state = {
            previous,
            loginEmail: '',
            loginPass: '',
            rememberMe: false,
            forgotPassword: false
        };

        autobind(this);
    }

    async onSubmitLogin(e) {
        e.preventDefault();

        const result = await Fetch.login(
            this.state.loginEmail, this.state.loginPass, this.state.rememberMe,
        );

        if (result.ok) {
            Storage.setRememberMe(this.state.rememberMe);
            this.context.afterLogin();
        } else {
            Util.oopsPopup(result.data.message);
            this.setState({ pass: '' });
        }
    }

    render() {
        return (
            <>
            <form className="login" onSubmit={this.onSubmitLogin}>
                <h2>Inloggen met je bestaande Account</h2>
                <input
                    className="loginEmail" value={this.state.loginEmail} type="email"
                    placeholder="Email" required autoComplete="email"
                    onChange={(e) => this.setState({ loginEmail: e.target.value })}
                />
                <input
                    className="loginPass" value={this.state.loginPass} type="password"
                    placeholder="Wachtwoord" required autoComplete="password" min="8" max="64"
                    onChange={(e) => this.setState({ loginPass: e.target.value })}
                />
                <div className="controls">
                    <div className="top">
                        <button
                            type="button" className="forgotPass"
                            onClick={() => this.setState({ forgotPassword: true })}>Wachtwoord Vergeten?
                        </button>

                        <div className="remember">
                            <input
                                id="rememberMe" type="checkbox" value={this.state.rememberMe}
                                onChange={(e) => this.setState({ rememberMe: e.target.checked })}
                            />
                            <label htmlFor="rememberMe">Onthouden</label>
                        </div>
                    </div>

                    <input type="submit" value="Inloggen" className="login" />
                </div>
            </form>

            {this.state.forgotPassword && <ForgotPassword onClose={() => this.setState({ forgotPassword: false })} />}
            </>
        );
    }
}

Login.contextType = Context;
