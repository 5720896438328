
import React from 'react'
import Socket from '../../utils/sockets';

export default function Invite({ invite }) {
    return (
        <div className="invite">
            <p>{invite.username}</p>

            <div className="buttons">
                <button className="accept" onClick={() => Socket.emit('chat-invite-accept', invite.id)}>Accepteren</button>
                <button className="decline" onClick={() => Socket.emit('chat-invite-decline', invite.id)}>Weigeren</button>
            </div>
        </div>
    )
}
