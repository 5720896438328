
import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../context/userContextProvider';
import Invites from './invites';

import Socket from '../../utils/sockets';
import Storage from '../../utils/storage';
import Util from '../../utils/util';
import Expand from '../icons/expand';

import AlertSound from '../../sounds/alert.mp3';

const stateMap = new Map([
    ['offline', 'Offline'],
    ['online', 'Online'],
    ['paused', 'Gepauzeerd'],
    ['busy', 'In Gesprek'],
]);

export default function State() {
    const context = useContext(Context);

    const [invites, setInvites] = useState([]);
    const [visible, setVisible] = useState(Storage.getString('stateVisibility', 'visible'));

    const toggleVisibility = () => {
        const next = visible === 'visible' ? 'hidden' : 'visible';
        Storage.store('stateVisibility', next);
        setVisible(next);
    };

    useEffect(() => {
        Socket.on('invites', (list) => {
            setInvites(list);
        });

        Socket.on('chat-invite-received', (invite) => {
            // Invite has been received

            const i = [ ...invites ];
            i.push(invite);
            setInvites(i);

            // Play a sound
            Util.playSound(AlertSound);
            // Update the title to notify the user that they have a new chat
            Util.changeTitle('Nieuw Gesprek!');

            setTimeout(() => {
                //Socket.emit('chat-invite-accept', invite.id);
                Util.changeTitle('holisticchat');
            }, 12000);
        });

        Socket.on('chat-invite-decline-success', (inviterId) => {
            const i = [ ...invites ];
            const index = i.findIndex((i) => i.id === inviterId);
            if (index !== -1) i.splice(index, 1);
            setInvites(i);
        });

        return () => {
            Socket.remove('invites');
            Socket.remove('chat-invite-received');
            Socket.remove('chat-invite-decline-success');
        };
    }, [invites]);

    if (!context.user || !context.user.roles.find((r) => r.name === 'Consultant')) {
        return <></>;
    }

    const s = context.user.state;
    const ps = context.user.phoneState;
    const buttonClassName = 'expand' + ((invites.length > 0 && visible !== 'visible') ? ' animate' : '');

    return (
        <div className={'consultant-state ' + visible}>
            <div className="hide">
                <button className={buttonClassName} onClick={toggleVisibility}><Expand /></button>
            </div>

            {s === 'reconnecting' ? <h3>Verbinding Maken</h3> : <h3>Je bent {stateMap.get(s)}</h3>}

            <div className="buttons">
                <button className="online" disabled={s === 'online' || s === 'busy'} onClick={() => Socket.emit('state-switch', 'online')}>Online</button>
                <button className="pause" disabled={s === 'paused' || s === 'busy'} onClick={() => Socket.emit('state-switch', 'paused')}>Pauze</button>
                <button className="offline" disabled={s === 'offline' || s === 'busy'} onClick={() => Socket.emit('state-switch', 'offline')}>Offline</button>
            </div>

            <h4>Bel beschikbaarheid</h4>
            <div className="phone">
                <span>Offline</span>
                <div className="slider"><div className={`thumb ${(ps ? 'online' : 'offline')}`} onClick={() =>  Socket.emit('phone-state-switch')}></div></div>
                <span>Online</span>
            </div>

            <Invites invites={invites} />
        </div>
    )
}
