
import React from 'react';
import { Navigate } from '../rensRouter/rensNavigate';

import '../scss/footer.scss';

export default function Footer() {
    return (
        <footer>
            <nav>
                <ul>
                    <li onClick={() => Navigate('/spiritual-coach')}>Spirituele Coach</li>
                    <li onClick={() => Navigate('/spiritual-center')}>Spiritueel centrum</li>
                    <li onClick={() => Navigate('/clairvoyant-online')}>Helderziende Online</li>
                </ul>
            </nav>

            <div className="center">
                <p className="copy">&copy; {new Date().getFullYear()} {window.location.hostname}</p>
                <p className="powered-by">Powered by <a href="https://holisticview.nl">holisticview.nl</a> and created by <a href="https://cesarpulles.nl">cesarpulles.nl</a></p>
                <span className="attribution">Sound Effects obtained from <a href="https://zapsplat.com" target="_blank" rel="noreferrer">zapsplat.com</a></span>
            </div>
        </footer>
    )
}
