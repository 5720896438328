
import React, { useContext, useState } from 'react';
import useWindowDimensions from '../utils/useWindowDimensions';

import { Context } from '../context/userContextProvider';
import { Navigate } from '../rensRouter/rensNavigate';

import Logo from './logo';
import User from './user';

import "../scss/header.scss";
import Util from '../utils/util';

const mobileWidth = 1550;

export default function Header() {
    const { width } = useWindowDimensions();
    const context = useContext(Context);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => { setMenuOpen(!menuOpen); };
    const closeMenu = () => { 
        if (menuOpen) setMenuOpen(false);
    };

    const mobileNavigate = (path) => {
        toggleMenu();
        Navigate(path);
    };

    const logout = () => {
        context.logout();
    };

    const page = Util.getPage();
    if (page.pathName.includes('/chat/')) {
        return <div className="empty-header"></div>
    }
    
    if (width <= mobileWidth) {
        return (
            <>
            <header className="mobile">
                <Logo />

                <div className={'hamburger' + (menuOpen ? ' open' : '')} onClick={toggleMenu}>
                    <div className="line one"></div>
                    <div className="line two"></div>
                    <div className="line three"></div>
                </div>
            </header>
            <div className={'menu' + (menuOpen ? ' open' : '')}>
                <button onClick={() => mobileNavigate('/')}>Home</button>
                {context.isAdmin() && <button onClick={() => mobileNavigate('/admin')}>Admin</button>}
                {context.isConsultant() && <button onClick={() => mobileNavigate('/edit/consultant')}>Consulent</button>}
                <button onClick={() => mobileNavigate('/credits')}>Credits Kopen</button>
                <button onClick={() => mobileNavigate('/explanation')}>Hoe werkt het</button>
                <button onClick={() => mobileNavigate('/about')}>Over Ons</button>
                <button onClick={() => mobileNavigate('/contact')}>Contact</button>
                {context.user && <button onClick={logout}>Uitloggen</button>}
            </div>
            <User onClose={closeMenu} />
            </>
        );
    }

    return (
        <header>
            <Logo />

            <button onClick={() => Navigate('/')}>Home</button>
            {context.isAdmin() && <button onClick={() => Navigate('/admin')}>Admin</button>}
            {context.isConsultant() && <button onClick={() => Navigate('/edit/consultant')}>Consulent</button>}
            <button onClick={() => Navigate('/credits')}>Credits Kopen</button>
            <button onClick={() => Navigate('/explanation')}>Hoe werkt het</button>
            <button onClick={() => Navigate('/about')}>Over Ons</button>
            <button onClick={() => Navigate('/contact')}>Contact</button>
            <User />
        </header>
    );
}
