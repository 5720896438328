import React from 'react';

import './rensAlert.scss';
import autobind from 'class-autobind';

/* eslint-disable */
function mergeObject(target) {
    for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i];
        for (const key in source) {
            if (source.hasOwnProperty(key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
}
/* eslint-enable */

function genRandomString() {
    return Math.random().toString().split('.')[1];
}

export const RensAlertSpawnType = {
    PUSH: 0, REPLACE: 1, REPLACE_SAME_TYPE: 2,
};

export const ModalType = {
    POPUP: 0,
    ACCEPT: 1,
    CONFIRM: 2,
    INPUT: 3,
    TOOLTIP: 4,
    ERROR_POPUP: 5,
};

const DEFAULT_TITLE = '[title] prop to change';
const DEFAULT_TEXT = '[text] prop to change';
const DEFUALT_ACCEPT = '[accept] prop to change';

class RensAlert {
    constructor() {
        this.container = React.createRef();
        this.uidMap = new Map();

        this.defaultOptions = {};
    }

    close(uid) {
        this.container.current.onClose(this.uidMap.get(uid));
    }

    setDefaultOptions(options) {
        this.defaultOptions = options;
    }

    popup(props, overrideOptions) {
        if (!this.container || !this.container.current) return;

        const key = `popup${genRandomString()}`;

        let mergedProps = mergeObject(
            {
                title: DEFAULT_TITLE,
                text: DEFAULT_TEXT,
                spawn: RensAlertSpawnType.REPLACE,
                type: ModalType.POPUP,
                time: 0,
                uid: genRandomString(),
            },
            props,
        );
        const options = overrideOptions || this.defaultOptions;
        mergedProps = mergeObject(mergedProps, options);

        this.uidMap.set(mergedProps.uid, key);
        this.container.current.addModal(
            <Popup
                key={key}
                id={key}
                options={mergedProps}
                onClose={this.container.current.onClose}
            />,
            mergedProps,
        );
    }

    accept(props, overrideOptions) {
        const key = `accept${genRandomString()}`;

        let mergedProps = mergeObject(
            {
                title: DEFAULT_TITLE,
                text: DEFAULT_TEXT,
                accept: DEFUALT_ACCEPT,
                spawn: RensAlertSpawnType.REPLACE,
                type: ModalType.ACCEPT,
                time: 0,
                uid: genRandomString(),
            },
            props,
        );
        const options = overrideOptions || this.defaultOptions;
        mergedProps = mergeObject(mergedProps, options);

        this.uidMap.set(mergedProps.uid, key);
        this.container.current.addModal(
            <Accept
                key={key}
                id={key}
                options={mergedProps}
                onClose={this.container.current.onClose}
            />,
            mergedProps,
        );
    }

    confirm(props, overrideOptions) {
        const key = `confirm${genRandomString()}`;

        let mergedProps = mergeObject({
            title: DEFAULT_TITLE,
            text: DEFAULT_TEXT,
            accept: DEFUALT_ACCEPT,
            decline: '[decline] prop to change',
            spawn: RensAlertSpawnType.REPLACE,
            type: ModalType.CONFIRM,
            time: 0,
            uid: genRandomString(),
        }, props);

        const options = overrideOptions || this.defaultOptions;
        mergedProps = mergeObject(mergedProps, options);

        this.uidMap.set(mergedProps.uid, key);
        this.container.current.addModal(
            <Confirm
                key={key}
                id={key}
                options={mergedProps}
                onClose={this.container.current.onClose}
            />,
            mergedProps,
        );
    }

    input(props, overrideOptions) {
        const key = `input${genRandomString()}`;

        let mergedProps = mergeObject(
            {
                title: DEFAULT_TITLE,
                text: DEFAULT_TEXT,
                accept: DEFUALT_ACCEPT,
                decline: '[decline] prop to change',
                spawn: RensAlertSpawnType.REPLACE,
                type: ModalType.INPUT,
                time: 0,
                uid: genRandomString(),
            },
            props,
        );
        const options = overrideOptions || this.defaultOptions;
        mergedProps = mergeObject(mergedProps, options);

        this.uidMap.set(mergedProps.uid, key);
        this.container.current.addModal(
            <Input
                key={key}
                id={key}
                options={mergedProps}
                onClose={this.container.current.onClose}
            />,
            mergedProps,
        );
    }

    tooltip(props, ref, overrideOptions) {
        const key = `tooltip${genRandomString()}`;

        let mergedProps = mergeObject({
            text: DEFAULT_TEXT,
            spawn: RensAlertSpawnType.PUSH,
            type: ModalType.TOOLTIP,
            time: 0,
            uid: genRandomString(),
        },
        props);
        const options = overrideOptions || this.defaultOptions;
        mergedProps = mergeObject(mergedProps, options);

        this.uidMap.set(mergedProps.uid, key);
        this.container.current.addModal(
            <Tooltip
                key={key}
                ref={ref}
                id={key}
                options={mergedProps}
                onClose={this.container.current.onClose}
            />,
            mergedProps,
        );
    }
}

export class RensAlertContainer extends React.Component {
    constructor() {
        super();
        autobind(this);

        this.state = { modals: [] };
    }

    onClose(id) {
        const { modals } = this.state;
        const index = modals.findIndex((m) => m.props.id === id);
        if (index !== -1) {
            modals.splice(index, 1);
            this.setState({ modals });
        }
    }

    addModal(modal, props) {
        let { modals } = this.state;

        switch (props.spawn) {
        case RensAlertSpawnType.REPLACE:
            modals = modals.filter(
                (m) => m.props.options.type === ModalType.TOOLTIP,
            );
            modals.push(modal);
            break;
        case RensAlertSpawnType.REPLACE_SAME_TYPE:
            modals = modals.filter(
                (m) => m.props.options.type !== props.type
            || m.props.options.type === ModalType.TOOLTIP,
            );
            modals.unshift(modal);
            break;
        case RensAlertSpawnType.PUSH:
        default:
            modals.unshift(modal);
            break;
        }

        this.setState({ modals });
    }

    render() {
        return <div id="rensAlert">{this.state.modals}</div>;
    }
}

class Modal extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = mergeObject({
            replace: true,
            time: 0,
            style: {},
            name: null,
            ref: null,
        },
        { id: props.id, ...props.options });
        this.preTransitionStyle = new Map();

        this.timeouts = [];

        if (this.state.time !== 0) {
            this.closeTimeout = setTimeout(() => {
                this.onClose();
            }, this.state.time);
        }

        if (this.state.transition) {
            this.addTransitions();
        }
    }

    _getModal() {
        return document.querySelector(`#${this.props.id}`);
    }

    addTransitions() {
        if (this.state.transition) {
            this._addOpenTransition();
            this._addCloseTransition();
        }
    }

    _addOpenTransition() {
        if (this.state.transition.open) {
            let { startAfter } = this.state.transition.open;
            if (!startAfter) startAfter = 100;

            this.timeouts.push(
                setTimeout(() => {
                    const modal = this._getModal();
                    if (!modal) return;

                    const currentStyle = { ...this.state.style };
                    const totalStyle = mergeObject(
                        currentStyle,
                        this.state.transition.open.style,
                    );

                    Object.entries(totalStyle).forEach(([key, value]) => {
                        this.preTransitionStyle.set(key, modal.style[key]);
                        modal.style[key] = value;
                    });
                }, startAfter),
            );
        }
    }

    _addCloseTransition() {
        if (this.state.transition.close) {
            const { startAfter } = this.state.transition.close;
            if (startAfter) {
                this.timeouts.push(
                    setTimeout(() => {
                        const modal = this._getModal();
                        if (!modal) return;

                        Object.entries({ ...this.state.transition.close.style })
                            .forEach(([key, value]) => {
                                modal.style[key] = value;
                            });
                    }, startAfter),
                );

                let closeAfter = this.state.transition.close.time;
                if (!closeAfter) closeAfter = 0;

                closeAfter += startAfter;
                this.timeouts.push(
                    setTimeout(() => {
                        this.onClose();
                    }, closeAfter),
                );
            }
        }
    }

    componentWillUnmount() {
        this.timeouts.forEach((t) => clearTimeout(t));
    }

    onClose() {
        this.props.onClose(this.state.id);
        if (this.state.onClose) this.state.onClose();
    }

    onAccept(val) {
        this.props.onClose(this.state.id);
        if (this.state.onAccept) this.state.onAccept(val);
    }

    onDecline() {
        this.props.onClose(this.state.id);
        if (this.state.onDecline) this.state.onDecline();
    }

    render() { return <h1>hoi</h1>; }
}

class Popup extends Modal {
    addTransitions() {
        super.addTransitions();
    }

    componentWillUnmount() { super.componentWillUnmount(); }

    onClose() { super.onClose(); }

    render() {
        let className = 'popup';
        if (this.state.name) className += ` ${this.state.name}`;

        return (
            <div
                style={this.state.style}
                id={this.props.id}
                onClick={this.onClick}
                className={className}
            >
                <div className="top">
                    <button onClick={this.onClose}>X</button>
                </div>
                <div className="content">
                    {this.state.title && <h3>{this.state.title}</h3>}
                    {this.state.text && <p>{this.state.text}</p>}
                    {this.state.innerHtml}
                </div>
                <div className="buttons" />
            </div>
        );
    }
}

class Accept extends Modal {
    addTransitions() { super.addTransitions(); }

    componentWillUnmount() { super.componentWillUnmount(); }

    onClose() { super.onClose(); }

    onAccept() {
        super.onAccept();
    }

    render() {
        let className = 'accept';
        if (this.state.name) className += ` ${this.state.name}`;

        return (
            <div
                style={this.state.style}
                id={this.props.id}
                className={className}
            >
                <div className="top">
                    <button onClick={this.onClose}>X</button>
                </div>
                <div className="content">
                    {this.state.title && <h3>{this.state.title}</h3>}
                    {this.state.text && <p>{this.state.text}</p>}
                    {this.state.innerHtml}
                </div>
                <div className="buttons">
                    <button className="acceptButton" onClick={this.onAccept}>
                        {this.state.accept}
                    </button>
                </div>
            </div>
        );
    }
}

class Confirm extends Modal {
    addTransitions() {
        super.addTransitions();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onClose() { super.onClose(); }

    onAccept() { super.onAccept(); }

    onDecline() { super.onDecline(); }

    render() {
        let className = 'confirm';
        if (this.state.name) className += ` ${this.state.name}`;

        return (
            <div
                style={this.state.style}
                id={this.props.id}
                className={className}
            >
                <div className="top">
                    <button onClick={this.onClose}>X</button>
                </div>
                <div className="content">
                    {this.state.title && <h3>{this.state.title}</h3>}
                    {this.state.text && <p>{this.state.text}</p>}
                    {this.state.innerHtml}

                    <div className="buttons">
                        <button className="acceptButton" onClick={this.onAccept}>
                            {this.state.accept}
                        </button>
                        <button className="declineButton" onClick={this.onDecline}>
                            {this.state.decline}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

class Input extends Modal {
    addTransitions() { super.addTransitions(); }

    componentWillUnmount() { super.componentWillUnmount(); }

    onClose() { super.onClose(); }

    onAccept(val) { super.onAccept(val); }

    onDecline() {
        super.onDecline();
    }

    onChange(e) {
        this.setState({ inputValue: e.target.value });
    }

    render() {
        let className = 'input';
        if (this.state.name) className += ` ${this.state.name}`;

        return (
            <div
                style={this.state.style}
                id={this.props.id}
                className={className}
            >
                <div className="top">
                    <button onClick={this.onClose}>X</button>
                </div>
                <div className="content">
                    {this.state.title && <h3>{this.state.title}</h3>}
                    {this.state.text && <p>{this.state.text}</p>}
                    {this.state.innerHtml}
                    <input
                        type="text"
                        onChange={this.onChange}
                        value={this.state.inputValue}
                    />
                </div>
                <div className="buttons">
                    <button
                        className="acceptButton"
                        onClick={() => this.onAccept(this.state.inputValue)}
                    >
                        {this.state.accept}
                    </button>
                    <button className="declineButton" onClick={this.onDecline}>
                        {this.state.decline}
                    </button>
                </div>
            </div>
        );
    }
}

class Tooltip extends Modal {
    addTransitions() { super.addTransitions(); }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onClose() {
        const { time } = this.state.transition.close;
        const modal = this._getModal();
        if (modal) {
            Object.entries({ ...this.state.transition.close.style })
                .forEach(([key, value]) => {
                    modal.style[key] = value;
                });
        }

        this.timeouts.push(
            setTimeout(() => {
                this.props.onClose(this.state.id);
            }, time),
        );
    }

    render() {
        let className = 'tooltip';
        if (this.state.name) className += ` ${this.state.name}`;

        return (
            <div
                style={this.state.style}
                id={this.props.id}
                className={className}
            >
                <div className="content">
                    {this.state.title && <h3>{this.state.title}</h3>}
                    {this.state.text && <p>{this.state.text}</p>}
                    {this.state.innerHtml}
                </div>
            </div>
        );
    }
}

const rensAlert = new RensAlert();
export default rensAlert;
