import React from 'react';
import Login from './login';
import Register from './register';

import '../../scss/auth.scss';

export default function Auth() {
    return (
        <div className="page auth">
            <div className="center">
                <Login />

                <div className="divider"></div>

                <Register />
            </div>
        </div>
    );
}
