import React, { useState } from 'react';
import { Ring } from 'react-css-spinners';

export default function LazyLoadImage({ src, alt, spinnerSize, onError, onClick, width, height }) {
    const [source, setSource] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    if (source !== src) {
        const image = new Image();
        setLoading(true);
        setError(false);

        image.onload = () => {
            setLoading(false);
            setError(false);
        };

        image.onerror = () => {
            setLoading(false);
            setError(true);
        };

        image.src = src;
        setSource(src);
    }

    const onImageClick = () => {
        if (onClick) onClick(src);
    };

    const style = {};
    if (width) style.width = width;
    if (height) style.height = height; 

    return (
        <div className="lazy-load-image" onClick={onImageClick} style={style}>
            <img className="" alt={alt} style={{ display: (!loading && !error) ? 'block' : 'none' }} src={(!loading && !error) ? src : null} width={width} height={height} />
            <Ring className="spinner" style={{ display: (loading && !error) ? 'block' : 'none' }} size={spinnerSize || 30} />
            <span className="error" style={{ display: error ? 'block' : 'none' }}>{onError}</span>
        </div>
    );
}
