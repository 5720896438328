
import React from 'react';

import Util from '../../utils/util';
import EditButton from '../buttons/edit';

import LazyLoadImage from './lazyLoadImage';

export default function Avatar({ src, name, edit = false, onClickEdit, onError = 'Afbeelding kan niet worden geladen' }) {
    if (!src) {
        const n = name || 'Undefined';
        const letter = n[0].toUpperCase();

        return (
            <div style={{ backgroundColor: Util.getRandomColorBasedOnName(n) }} className="avatar">
                {edit && <EditButton onClick={onClickEdit} />}
                <p>{letter}</p>
            </div>
        );
    }

    return (
        <div className="avatar">
            {edit && <EditButton onClick={onClickEdit} />}
            <LazyLoadImage src={`${process.env.REACT_APP_API_URL}/image/${src}`} onError={onError} />
        </div>
    )
}
