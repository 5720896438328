
export default function EditButton({ onClick, name }) {
    let cName = 'edit';
    if (name) cName += name;

    return (
        <button className={cName} onClick={onClick}>
            <svg viewBox="0 0 512.001 512.001">
                <path d="M496.063,62.299l-46.396-46.4c-21.2-21.199-55.69-21.198-76.888,0l-18.16,18.161l123.284,123.294l18.16-18.161 C517.311,117.944,517.314,83.55,496.063,62.299z"/>
                <path d="M22.012,376.747L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143 l117.512-21.763L22.012,376.747z"/>
                <polygon points="333.407,55.274 38.198,350.506 161.482,473.799 456.691,178.568"/>
            </svg>
        </button>
    );
};