import React, { Component } from 'react';

export default class Warning extends Component {
    render() {
        return (
            <svg viewBox="0 0 512.044 512.044" xmlns="http://www.w3.org/2000/svg">
                <path d="m505.985 415.081-210.751-363.421c-8.062-13.964-23.087-22.639-39.211-22.639-16.114 0-31.131 8.665-39.196 22.614l-210.781 363.471c-8.062 13.964-8.062 31.313 0 45.277s23.087 22.639 39.211 22.639h421.531c16.124 0 31.149-8.675 39.211-22.639s8.062-31.313-.014-45.302zm-25.966 30.303c-2.721 4.711-7.79 7.638-13.23 7.638h-421.532c-5.44 0-10.51-2.927-13.23-7.638-2.72-4.712-2.72-10.566-.015-15.252 0 0 210.775-363.462 210.78-363.471 2.721-4.711 7.79-7.638 13.23-7.638s10.51 2.927 13.245 7.664l210.751 363.42c2.721 4.711 2.721 10.565.001 15.277z" />
                <path d="m256.023 243.022c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15s15-6.716 15-15v-60c0-8.284-6.716-15-15-15z" />
                <circle cx="256.023" cy="378.022" r="15" />
            </svg>
        );
    }
}
