
import React from 'react';
import Invite from './invite';

export default function Invites({ invites }) {
    return (
        <div className="invites">
            <h3>Uitnodigingen</h3>

            {invites.map((i) => <Invite key={i.id} invite={i} />)}
            {invites.length === 0 && <span className="empty">Nog Geen</span>}
        </div>
    )
}
