import React from 'react';
import ColorHash from 'color-hash';

import RensAlert from '../rensAlert/rensAlert';
import {
    OOPS_POPUP,
    NOTIFY_POPUP,
    STATIC_CENTERED_POPUP,
    ERROR_POPUP,
} from '../rensAlertStyles';

import Check from '../components/icons/check';
import Cross from '../components/icons/cross';
import Warning from '../components/icons/warning';



const colorHash = new ColorHash();
const intervals = new Map();

const httpRegex = new RegExp(/^https?:\/\//);
const MS_TO_MINS = 1000 * 60;

const Util = {
    playSound(url) {
        new Audio(url).play();
    },

    changeTitle(title) {
        document.title = title;
    },

    secondsSinceDate(s) {
        let start = typeof s === 'string' ? new Date(s) : s;
        const now = new Date();

        return Math.floor((now - start) / 1000);
    },

    isValidUrl(str) {
		try {
			new URL(str);
			return httpRegex.test(str);
		}
		catch (err) {
			return false;
		}
	},

    onCopyUrl() {
        const urlElement = document.querySelector('.copy-url');
        urlElement.select();
        document.execCommand('copy');
    },

    timeSince(dt) {
        const date = new Date(dt);
        const now = new Date();

        const mins = (now - date) / MS_TO_MINS;
        if (mins < 60) {
            if (mins < 1) return 'minder dan 1 minuut geleden';

            const m = Math.floor(mins);
            return `${m} ${m === 1 ? 'minuut' : 'minuten'} geleden`;
        }

        const hours = mins / 60;
        if (hours < 24) {
            const h = Math.floor(hours);
            return `${h} ${h === 1 ? 'uur' : 'uur'} geleden`;
        }

        const days = hours / 24;
        if (days < 30) {
            const d = Math.floor(days);
            return `${d} ${d === 1 ? 'dag' : 'dagen'} geleden`;
        }

        const months = days / 30;
        if (months < 12) {
            const m = Math.floor(months);
            return `${m} ${m === 1 ? 'maand' : 'maanden'} geleden`;
        }

        return `op ${date.toLocaleDateString(undefined)}`;
    },

    makeDateString(date) {
        const d = new Date(date);
        return d.toLocaleDateString('nl-NL', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    },

    makeTimeStamp(d) {
        const now = new Date();
        const date = new Date(d);

        const hours = (now - date) / (MS_TO_MINS * 60);
        if (hours < 24) {
            return date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });
        }

        return date.toLocaleDateString('nl-NL', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    },

    votesToSmallForm(votes) {
        if (votes < 999) return votes;
        return `${(votes / 1000).toFixed(1)}k`;
    },

    isEmptyOrWhitespaces(str) {
        return !(/\S/.test(str));
    },

    wordIncludes(word, check) {
        return word.toUpperCase().includes(check.toUpperCase());
    },

    capitalizeWord(w) {
        if (!w) return w;
        return `${w[0].toUpperCase()}${w.substr(1, w.length - 1)}`;
    },

    addInterval(name, cb, time) {
        if (intervals.has(name)) {
            const id = intervals.get(name);
            clearInterval(id);
            intervals.delete(name);
        }

        const id = setInterval(cb, time);
        intervals.set(name, id);
    },

    removeInterval(name) {
        if (intervals.has(name)) {
            clearInterval(intervals.get(name));
            intervals.delete(name);
        }
    },

    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    },

    refresh() {
        this.sendEvent('refresh');
    },

    sendEvent(name, args = {}) {
        window.dispatchEvent(new CustomEvent(name, { detail: args }))
    },

    parseJwt(token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    },

    animateHeight(element) {
        if (element.classList.contains('visible')) {
            element.style.height = '0px';
        } else {
            element.style.height = `${element.scrollHeight}px`;
        }

        element.classList.toggle('visible');
    },

    getRandomColorBasedOnName(name) {
        return colorHash.hex(name);
    },

    getPage() {
        const loc = window.location;
        const pathParts = loc.pathname.split('/');
        const pathEnd = pathParts.length === 0 ? '' : pathParts[pathParts.length - 1];

        return {
            pathName: loc.pathname,
            pathEnd,
            hash: loc.hash
        };
    },

    getQuery(name) {
        return new URLSearchParams(window.location.search).get(name);
    },

    hasQuery(name) {
        return new URLSearchParams(window.location.search).has(name);
    },

    getAllQueries() {
        return new URLSearchParams(window.location.search).getAll();
    },

    removeQuery(name) {
        if (this.hasQuery(name)) {
            const queries = new URLSearchParams(window.location.search);
            queries.delete(name);

            let newUrl = window.location.origin + window.location.pathname;
            if (queries.getAll().length > 0) newUrl += `?${queries.toString()}`;

            window.history.replaceState({}, document.title, newUrl);
        }
    },

    padTag(tag) {
        return `#${tag.toString().padStart(4, '0')}`;
    },

    updatePopup(msg) {
        RensAlert.popup({
            title: '',
            text: '',
            name: 'update',
            innerHtml: (
                <div className="pop">
                    <div className="left"><Check/></div>
                    <p>{msg}</p>
                </div>
            ),
        }, OOPS_POPUP);
    },

    acceptPopup(msg, onAccept, onDecline) {
        RensAlert.confirm({
            title: '',
            text: '',
            innerHtml: (
                <div className="pop">
                    <div className="left"><Warning/></div>
                    <p>{msg}</p>
                </div>
            ),
            accept: 'Ja',
            decline: 'Nee',
            onAccept,
            onDecline
        }, STATIC_CENTERED_POPUP);
    },

    notifyPopup(msg) {
        RensAlert.popup({
            title: '',
            text: '',
            name: 'notify',
            innerHtml: (
                <div className="notify">
                    <p>{msg}</p>
                </div>
            ),
        }, NOTIFY_POPUP);
    },

    notifySuccessPopup(msg) {
        RensAlert.popup({
            title: '',
            text: '',
            name: 'update',
            innerHtml: (
                <div className="pop">
                    <div className="left"><Check/></div>
                    <p>{msg}</p>
                </div>
            ),
        }, NOTIFY_POPUP);
    },

    notifyWarnPopup(msg) {
        RensAlert.popup({
            title: '',
            text: '',
            name: 'oops',
            innerHtml: (
                <div className="pop">
                    <div className="left"><Warning /></div>
                    <p>{msg}</p>
                </div>
            ),
        }, NOTIFY_POPUP);
    },

    errorPopup(msg) {
        RensAlert.popup({
            title: '',
            text: '',
            name: 'error',
            innerHtml: (
                <div className="pop">
                    <div className="left"><Cross/></div>
                    <p>{msg}</p>
                </div>
            ),
        }, ERROR_POPUP);
    },

    oopsPopup(msg) {
        RensAlert.popup({
            title: '',
            text: '',
            name: 'oops',
            innerHtml: (
                <div className="pop">
                    <div className="left"><Warning/></div>
                    <p>{msg}</p>
                </div>
            ),
        }, OOPS_POPUP);
    },

    notImplementedPopup() {
        RensAlert.popup({
            title: '',
            text: '',
            name: 'oops',
            innerHtml: (
                <div className="pop">
                    <div className="left"><Warning/></div>
                    <p>Helaas deze functie werkt nog niet.</p>
                </div>
            ),
        }, OOPS_POPUP);
    },
};

export default Util;
