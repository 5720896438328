
import React, { useState, useEffect } from 'react';
import { Navigate } from '../../rensRouter/rensNavigate';

import Loading from '../base/loading';

import Fetch from '../../utils/fetchUtil';
import Util from '../../utils/util';

export default function ChangeEmail() {
    const [token, setToken] = useState(null);

    const verifyEmail = async (id) => {
        const result = await Fetch.verifyEmail(id);
        if (result.ok) {
            Util.updatePopup('Email gewijzigd. Je kunt nu inloggen met je nieuwe Email.');
            Navigate('/auth');
        }
    };

    useEffect(() => {
        const id = Util.getPage().pathEnd;
        if (id !== token) {
            setToken(id);
            verifyEmail(id);
        }
    }, [setToken, token]);

    return (
        <div className="page change-email">
            <Loading />
        </div>
    )
}
