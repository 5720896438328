import React, { Component } from 'react';

import autobind from 'class-autobind';
import Logger from '../utils/logger';
import userContext from './userContext';

import Socket from '../utils/sockets';
import Util from '../utils/util';
import Storage from '../utils/storage';

import ChatMessageSound from '../sounds/chat_message.mp3';

import { Navigate } from '../rensRouter/rensNavigate';

export const Context = React.createContext(null);

let intervalId = null;

export default class UserContextProvider extends Component {
    constructor() {
        super();

        this.state = {
            token: '',
            userId: 0,
            user: null,
            loggedIn: 'none',
        };

        this.log = new Logger('UserContextProvider');
        autobind(this);

        window.addEventListener('refresh', this.refresh);
    }

    onInterval() {
        const pauseTime = Storage.getItem('pauseStarted', null);
        if (pauseTime) {
            const now = new Date();
            if (now - new Date(pauseTime) > 1000 *60 * 30) { // 30 minutes maximum pausing
                Socket.emit('state-switch', 'offline');
            }
        }
    }

    componentWillUnmount() {
        if (intervalId) clearInterval(intervalId);
    }

    setup() {
        intervalId = setInterval(() => this.onInterval(), 5000);

        Socket.on('verified', () => {
            userContext.verified = true;
        });

        Socket.on('chat-start', (id) => {
            Navigate(`/chat/${id}`);
        });

        Socket.on('chat-info', (id, state, you, other, start, pauseStart, youPaused, waitForUser, waitForConsultant) => {
            const page = Util.getPage().pathName;
            if (!page.includes('/chat')) {
                Navigate(`/chat/${id}`);
            }

            Util.sendEvent('chat-info', { state, you, other, start, pauseStart, youPaused, waitForUser, waitForConsultant });
        });

        Socket.on('chat-invite-sent', (id) => {
            Navigate(`/waiting-invite-sent?id=${id}`);
        });

        Socket.on('chat-invite-declined', (username) => {
            Util.updatePopup(`${username} heeft uw uitnodiging niet geaccepteerd of is offline gegaan.`);
            Navigate('/');
        });

        Socket.on('chat-invite-canceled', () => Navigate('/'));

        Socket.on('credits', (count) => {
            userContext.user.credits = count;
            this.refresh();
        });

        Socket.on('notification', (notification) => {
            Util.notifyPopup(<>Je hebt een nieuw bericht ontvangen van:<br/> "{notification.sender.username}"</>);
            userContext.notificationCount++;

            Util.playSound(ChatMessageSound);

            Util.sendEvent('reload-notifications');
            this.refresh();
        });

        this.refresh();
    }

    async isLoggedIn() {
        const state = await userContext.isLoggedIn();
        this.setState({ ...userContext });
        return state;
    }

    async afterLogin(token) {
        await userContext.afterLogin(token);
        this.setState({ ...userContext });
    }

    logout() {
        userContext.logout();
        this.setState({ ...userContext });
    }

    async refresh() {
        this.log.info('refresh()');
        if (this.props) this.setState({ ...userContext });
    }

    isConsultant(id = null) {
        if (id === null) {
            return userContext.user && userContext.user.roles.find((r) => r.name === 'Consultant');
        } else {
            return userContext.consultants.find((c) => c.user_id === id);
        }
    }

    isAdmin() {
        return userContext.user && userContext.user.roles.find((r) => r.name === 'Admin');
    }

    setConsultant(consultant) {
        const index = userContext.consultants.findIndex((c) => c.user_id === consultant.user_id);
        if (index !== -1) userContext.consultants.splice(index, 1, consultant);
    }

    getConsultant(id) {
        return userContext.consultants.find((c) => c.user_id === id);
    }

    async updateNotificationCount() {
        await userContext.getNotificationCount();
        this.refresh();
    }

    render() {
        const context = {
            afterLogin: this.afterLogin,
            logout: this.logout,
            refresh: this.refresh,
            isConsultant: this.isConsultant,
            setConsultant: this.setConsultant,
            getConsultant: this.getConsultant,
            isAdmin: this.isAdmin,
            updateNotificationCount: this.updateNotificationCount,
            ...this.state,
        };

        return (
            <Context.Provider value={context}>{this.props.children}</Context.Provider>
        );
    }
}
