/* eslint-disable */
const LOG_LEVEL = {
    INFO: 'INFO',
    DEBUG: 'DEBUG',
    ERROR: 'ERROR',
};

class Logger {
    constructor(type) {
        this.type = type;
    }

    info(msg, ...params) {
        this.log(LOG_LEVEL.INFO, msg, ...params);
    }

    debug(msg, ...params) {
        if (process.env.REACT_APP_DEBUG === 'true') {
            this.log(LOG_LEVEL.DEBUG, msg, ...params);
        }
    }

    error(msg, ...params) {
        this.log(LOG_LEVEL.ERROR, msg, ...params);
    }

    log(logLevel, msg, ..._params) {
        const params = [];
        _params.forEach((p) => {
            if (typeof p === 'object') {
                params.push(JSON.stringify(p));
            } else {
                params.push(p);
            }
        });

        const strParams = params.join(', ');
        if (strParams.length === 0) {
            console.log(`[${logLevel} [${this.type}] ${msg}]`);
        } else {
            console.log(`[${logLevel} [${this.type}] ${msg} ${strParams}]`);
        }
    }
}

export default Logger;
