
import io from 'socket.io-client';
import Logger from './logger';
import Util from './util';
import Storage from './storage';

import userContext from '../context/userContext';
import { Navigate } from '../rensRouter/rensNavigate';

const ERROR_CODES = {
    INVITE_NOT_FOUND: 0,
    USER_NOT_FOUND: 1,
    CHAT_NOT_FOUND: 2,
    NOT_IN_CHAT: 3,
    NOT_CONSULTANT: 4,
    NOT_ENOUGH_CREDITS: 5,
    ALREADY_INVITED: 6
};

const consultantStates = new Map([
    [ 'online',     4 ],
    [ 'busy',       3 ],
    [ 'paused',     2 ],
    [ 'offline',    1 ]
]);

const consultantSort = (a, b) => {
    return (consultantStates.get(b.state) + b.phoneState) - (consultantStates.get(a.state) + a.phoneState);
};

class Socket {
    constructor() {
        this.socket = io(process.env.REACT_APP_SOCKET_URL, { path: '/sockets' });
        this.logger = new Logger('Socket');

        this.init();
    }

    init() {
        this.on('onError', (err) => {
            this.logger.error(`onError ${err.msg}`);

            Util.errorPopup(err.msg);

            switch (err.code) {
                case ERROR_CODES.INVITE_NOT_FOUND:
                case ERROR_CODES.USER_NOT_FOUND:
                case ERROR_CODES.CHAT_NOT_FOUND:
                    Navigate('/');
                    Util.refresh();
                    break;
                default:
                    return;
            }
        });

        this.on('state-list', async (list) => {
            while (!userContext.consultants) {
                await Util.sleep(500);
            }

            userContext.consultants.forEach((c) => {
                let state = 'offline';
                let phoneState = false;

                const listConsultant = list.find((l) => l.id === c.user_id);
                if (listConsultant) {
                    state = listConsultant.state;
                    phoneState = listConsultant.phoneState;
                }

                c.state = state;
                c.phoneState = phoneState;
            });

            const newConsultants = [ ...userContext.consultants.sort(consultantSort) ];
            userContext.consultants = newConsultants;
            
            Util.refresh();
        });

        this.on('state', async (id, state) => {
            while (!userContext.consultants) {
                await Util.sleep(500);
            }

            const consultantIndex = userContext.consultants.findIndex((c) => c.user_id === id);
            if (consultantIndex !== -1) {
                const consultant = userContext.consultants[consultantIndex];
                consultant.state = state;

                let newConsultants;
                if (state === 'online') {
                    newConsultants = [ userContext.consultants.splice(consultantIndex, 1)[0], ...userContext.consultants.sort(consultantSort) ];
                } else {
                    newConsultants = [ ...userContext.consultants.sort(consultantSort) ];
                }

                userContext.consultants = newConsultants;
            }

            if (id === userContext.userId && userContext.user.state !== state) {
                userContext.user.state = state;
                if (state === 'paused') {
                    if (!userContext.isPaused) {
                        userContext.isPaused = true;
                        Storage.store('pauseStarted', new Date());
                    }
                } else {
                    userContext.isPaused = false;
                    Storage.store('pauseStarted', null);
                }
            }

            Util.sendEvent('state', { id, state });
            Util.refresh();
        });

        this.on('phone-state', async (id, state) => {
            while (!userContext.consultants) {
                await Util.sleep(500);
            }

            const consultantIndex = userContext.consultants.findIndex((c) => c.user_id === id);
            if (consultantIndex !== -1) {
                const consultant = userContext.consultants[consultantIndex];
                consultant.phoneState = state;

                let newConsultants;
                if (state) {
                    newConsultants = [ userContext.consultants.splice(consultantIndex, 1)[0], ...userContext.consultants.sort(consultantSort) ];
                } else {
                    newConsultants = [ ...userContext.consultants.sort(consultantSort) ];
                }

                userContext.consultants = newConsultants;
            }

            if (id === userContext.userId && userContext.user.phoneState !== state) {
                userContext.user.phoneState = state;
            }

            Util.sendEvent('phone-state', { id, state });
            Util.refresh();
        });

        this.on('connect', async () => {
            while (!userContext.jwt) {
                await Util.sleep(500);
            }

            this.emit('verify', userContext.jwt);
        });

        this.on('disconnect', () => {
            this.logger.info('Disconnected');
            userContext.verified = false;
        });
    }

    async getChatInfo(chatId) {
        while (!userContext.verified) {
            await Util.sleep(500);
        }
        this.emit('chat-get-info', chatId);
    }

    disconnect() {
        this.socket.disconnect();
    }

    isConnected() {
        return this.socket.connected;
    }

    on(path, handlerFunc) {
        this.remove(path);
        this.socket.on(path, (...args) => {
            let msg = `Receiving on "${path}"`;

            this.logger.debug(msg, ...args);
            handlerFunc(...args);
        });
    }

    remove(path) {
        this.socket.removeAllListeners(path);
    }

    emit(path, ...data) {
        this.logger.debug(`Emitting to "${path}"`, ...data);
        this.socket.emit(path, ...data);
    }
}

const socket = new Socket();
export default socket;