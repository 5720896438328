import autobind from 'class-autobind';
import React, { Component } from 'react';
import { Wave } from 'react-css-spinners';
import { Navigate } from '../../rensRouter/rensNavigate';

import Fetch from '../../utils/fetchUtil';
import Util from '../../utils/util';

export default class Verify extends Component {
    constructor() {
        super();

        this.state = {
            verified: true,
        };

        autobind(this);
    }

    componentDidMount() {
        setTimeout(async () => {
            const uuid = Util.getPage().pathEnd;
            if (!uuid || typeof uuid !== 'string' || uuid.length !== 36) {
                Navigate('/auth');
                return;
            }

            await Fetch.verify(uuid);
            Navigate('/auth');
        }, 500);
    }

    getContent() {
        if (!this.state.verified) {
            return <div className="verifyText"><h2>Account activeren</h2><Wave /></div>;
        }

        return (
            <div className="verifiedText">
                <h2>Uw Account is geactiveerd!</h2>
                <button onClick={() => Navigate('/auth')}>Klik hier om in te loggen</button>
            </div>
        );
    }

    render() {
        return (
            <div className="page verify">
                <div className="content">
                    {this.getContent()}
                </div>
            </div>
        );
    }
}
