import Fetch from '../utils/fetchUtil';
import Logger from '../utils/logger';
import Util from '../utils/util';
import Storage from '../utils/storage';

class UserContext {
    constructor() {
        this.token = '';

        this.userId = 0;
        this.user = null;

        this.loggedIn = 'none';
        this.verified = false;

        this.log = new Logger('UserContext');
        this.setupSocketsCalled = false;
        this.notificationCount = 0;

        this.isPaused = Storage.hasItem
    }

    clear() {
        this.token = '';
        this.loggedIn = false;
        this.setupSocketsCalled = false;
        this.user = null;
        this.userId = 0;
    }

    setup() {
        return Promise.all([ this.getMe(), this.getNotificationCount()]);
    }

    async afterLogin() {
        this.log.debug('afterLogin()');
        this.loggedIn = 'yes';

        const path = Util.getPage();
        if (path.pathName.includes('/auth')) {
            window.location.href = '/';
        } else {
            window.location.reload();
        }
    }

    async getMe() {
        const result = await Fetch.getMe();
        if (result.ok) {
            this.userId = result.data.user.id;
            this.user = result.data.user;
            this.jwt = result.data.jwt;
        }
    }

    async getConsultants() {
        const result = await Fetch.getConsultants();
        if (result.ok) {
            this.consultants = result.data;
        }
    }

    async getNotificationCount() {
        const result = await Fetch.getNotificationCount();
        if (result.ok) {
            this.notificationCount = result.data.count;
        }
    }

    async logout() {
        this.log.debug('logout()');

        const dbResult = await Fetch.logout();
        if (dbResult.ok) {
            this.clear();
            window.location.href = '/';
            Storage.clear();
        }
    }
}

const userContext = new UserContext();
export default userContext;
