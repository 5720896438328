import React, { Component } from 'react';

export default class Expand extends Component {
	render() {
		return (
			<svg version="1.1" className="expand" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306 306" {...this.props}>
				<polygon points="270.3,58.65 153,175.95 35.7,58.65 0,94.35 153,247.35 306,94.35" />
			</svg>
		);
	}
}