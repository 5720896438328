function Navigate(path) {
    const url = new URL(path, window.location.origin);

    window.history.pushState({}, '', url);

    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
}

function AddQueryParam(name, value) {
    const params = new URLSearchParams(window.location.search);
    params.set(name, value);
    window.history.pushState({}, '', `?${params.toString()}`);
}

function GetQueryParam(name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
}

function HasQueryParam(name) {
    const params = new URLSearchParams(window.location.search);
    return params.has(name);
}

function RemoveQueryParam(name) {
    const params = new URLSearchParams(window.location.search);
    params.delete(name);
    window.history.pushState({}, '', `?${params.toString()}`);
}

export {
    Navigate, AddQueryParam, GetQueryParam, HasQueryParam, RemoveQueryParam
};
