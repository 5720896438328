
import React, { useState } from 'react';

import Fetch from '../../utils/fetchUtil';
import Util from '../../utils/util';
import CloseButton from '../buttons/close';

export default function ForgotPassword({ onClose }) {
    const [email, setEmail] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();
        onClose();

        const result = await Fetch.forgotPassword(email);
        if (result.ok) {
            Util.updatePopup(`Als u een geldig email heeft ingevuld krijgt u een email met verdere instructies om uw wachtwoord te resetten.`);
        }
    }

    return (
        <div className="mask">
            <div className="popup forgotPass">
                <h2>Uw Wachtwoord Resetten</h2>
                <CloseButton onClick={onClose} />

                <form onSubmit={onSubmit}>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />

                    <input type="submit" value="Wachtwoord Reset Link Sturen" />
                </form>
            </div>
        </div>
    );  
}
