import Logger from './logger';
import Util from './util';
import { Navigate } from '../rensRouter/rensNavigate';

const ERROR_MESSAGES = new Map([
    [404, 'Not found 😥'],
    [413, 'Your Image/Request is too large'],
    [500, 'Internal server error 😥'],
    [503, 'Service temporarily unavailable 😢'],
]);

const DEFAULT_ERROR_MESSAGE = 'Something unexpected happened';

class FetchUtil {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_URL;
        this.log = new Logger('Request');
    }

    register(username, email, password, ref) {
        return this.post(
            '/auth/register',
            { username, email, password, ref },
            true,
        );
    }

    login(email, password, rememberMe) {
        return this.post('/auth/login', { email, password, rememberMe }, true);
    }

    logout() {
        return this.post('/auth/logout');
    }

    isLoggedIn() {
        return this.get('/auth', true);
    }

    getMe() {
        return this.get('/user/me', true);
    }

    verify(verifyId) {
        return this.post('/auth/verify', { verifyId });
    }

    forgotPassword(email) {
        return this.post('/auth/forgot-password', { email });
    }

    resetPassword(token, password) {
        return this.post('/auth/reset-password', { token, password });
    }

    changePassword(oldPassword, newPassword) {
        return this.put('/auth/password', { oldPassword, newPassword });
    }

    changeEmail(password, email) {
        return this.put('/auth/email', { password, email });
    }

    verifyEmail(token) {
        return this.post('/auth/verify-new-email', { token });
    }

    updateUser(username, gender, date) {
        return this.post('/user', { username, gender, date });
    }

    updateAvatar(formdata) {
        return this.postForm('/user/avatar', formdata);
    }

    getConsultants() {
        return this.get('/consultant');
    }

    getConsultant(id) {
        return this.get(`/consultant/${id}`);
    }

    getConsultantReviews(id, page) {
        return this.get(`/review/${id}?page=${page}&sort=time&direction=asc`);
    }

    startPayment(size) {
        return this.post(`/payment/start`, { size });
    }

    getOrder(id) {
        return this.get(`/payment/${id}`, true);
    }

    getCompletedOrderCount() {
        return this.get('/payment/completed');
    }

    getOptions() {
        return this.get('/payment/options');
    }

    getOrders(p) {
        return this.get(`/payment/all?page=${p}&sort=created_at&direction=asc`);
    }

    updateSpecialities(specialities) {
        return this.post(`/consultant/specialities`, { specialities });
    }

    updateMethods(methods) {
        return this.post(`/consultant/methods`, { methods });
    }

    updateCommunications(communications) {
        return this.post(`/consultant/communications`, { communications });
    }

    updateNameTitle(name, title) {
        return this.post(`/consultant/name-title`, { name, title });
    }

    updatePhone(phone) {
        return this.post(`/consultant/phone`, { phone });
    }

    updateDescription(description) {
        return this.post(`/consultant/description`, { description });
    }

    updateTimeslots(timeslots) {
        return this.post(`/consultant/timeslots`, { timeslots });
    }

    updateVideo(video) {
        return this.post(`/consultant/video`, { video });
    }

    updateConsultantAvatar(formdata) {
        return this.postForm(`/consultant/avatar`, formdata);
    }

    getMessages(id, p) {
        return this.get(`/chat/${id}?page=${p}&sort=timestamp&direction=desc`);
    }

    getChats(page) {
        return this.get(`/chat/search?page=${page}&sort=start&direction=asc`);
    }

    getChatHistoryById(id) {
        return this.get(`/chat/history/${id}`);
    }

    getNotificationCount() {
        return this.get('/notification/count');
    }

    getNotifications(page) {
        return this.get(`/notification?page=${page}&sort=timestamp&direction=desc`);
    }

    closeNotification(chatId) {
        return this.delete(`/notification/${chatId}`);
    }

    uploadChatImage(image) {
        const formData = new FormData();
        formData.set('image', image);

        return this.postForm(`/image/chat`, formData);
    }

    uploadPostImage(image) {
        const formData = new FormData();
        formData.set('image', image);

        return this.postForm(`/image/post`, formData);
    }

    findAllByUsername(search, page) {
        return this.post(`/user/search?page=${page}&sort=username&direction=asc`, { search });
    }

    updateRole(id, role, enable) {
        return this.put(`/admin/update-role`, { id, role, enable });
    }

    makePost(content, type) {
        return this.post('/post', { content, type });
    }

    deletePost(postId) {
        return this.delete(`/post/${postId}`);
    }

    updatePost(id, content, type) {
        return this.put('/post', { id, content, type });
    }

    getConsultantPosts(page, sort, dir) {
        return this.get(`/post/consultant?page=${page}&sort=${sort}&direction=${dir}`);
    }

    getUserPosts(page, sort, dir) {
        return this.get(`/post/user?page=${page}&sort=${sort}&direction=${dir}`);
    }

    getPostById(id) {
        return this.get(`/post/${id}`);
    }

    getResponses(postId, page, sort, dir) {
        return this.get(`/response/${postId}?page=${page}&sort=${sort}&direction=${dir}`);
    }

    makeResponse(postId, text) {
        return this.post('/response', { id: postId, text });
    }

    async downloadImage(id) {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/image/${id}`, { method: 'GET', headers: {} });
            const buffer = await result.arrayBuffer();

            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'holisticchat.png');

            document.body.appendChild(link);
            link.click();
        }
        catch (err) {
            this.log.error('Download failed: ', err);
        }
    }

    put(url, body, ignoreErrors = false) {
        const req = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (body !== null) req.body = JSON.stringify(body);
        return this.fetchMethod(url, req, ignoreErrors);
    }

    get(url, ignoreErrors = false) {
        const req = {
            method: 'GET',
        };
        return this.fetchMethod(url, req, ignoreErrors);
    }

    delete(url, ignoreErrors = false) {
        const req = { method: 'DELETE' };
        return this.fetchMethod(url, req, ignoreErrors);
    }

    post(url, body, ignoreErrors = false) {
        const req = { method: 'POST' };
        req.headers = {
            'Content-Type': 'application/json',
        };

        if (body !== null) req.body = JSON.stringify(body);
        return this.fetchMethod(url, req, ignoreErrors);
    }

    async postForm(url, formData, method = 'POST') {
        return this.fetchMethod(
            url,
            { method, body: formData },
            false,
        );
    }

    async fetchMethod(url, req, ignoreErrors) {
        const res = {};
        this.log.debug(`${req.method} ${url}`);

        req.credentials = 'include';

        await fetch(this.API_URL + url, req)
            .then(async (data) => {
                if (data.redirected) {
                    res.redirected = data.redirected;
                    res.url = data.url;
                } else {
                    res.status = data.status;
                    res.ok = data.ok;
                    try {
                        res.data = await data.json();
                    } catch (ex) {
                        this.log.debug('No data.message');
                    }
                }
            })
            .catch((error) => this.log.error(error));

        this._handleResponse(res, ignoreErrors);
        return res;
    }

    _handleResponse(res, ignoreErrors) {
        // We've been redirected by NGINX
        if (res.redirected) {
            Navigate(res.url);
            Util.errorPopup('You need to be logged in to see this page');
            return;
        }

        if (!res.ok) {
            if (!res.data || !res.data.message) {
                res.data = {
                    message: ERROR_MESSAGES.has(res.status)
                        ? ERROR_MESSAGES.get(res.status) : DEFAULT_ERROR_MESSAGE,
                };
            }

            if (!ignoreErrors) Util.errorPopup(res.data.message);
        }
    }
}

const fetchUtil = new FetchUtil();
export default fetchUtil;
