
import React, { useContext } from 'react';
import useWindowDimensions from '../utils/useWindowDimensions';

import { Context } from '../context/userContextProvider';
import { Navigate } from '../rensRouter/rensNavigate';

import Avatar from './base/avatar';

import "../scss/user.scss";

const mobileWidth = 1550;

export default function User({ onClose }) {
    const { width } = useWindowDimensions();
    const context = useContext(Context);

    const logout = () => {
        context.logout();
    };

    const onClickAvatar = () => {
        if (onClose) onClose();
        Navigate('/profile');
    };

    if (context.user) {
        const me = context.user;

        return (
            <div className={'user' + (width <= mobileWidth ? ' mobile': '')}>
                <button onClick={onClickAvatar} className="profile">
                    <Avatar src={me.avatar} name={me.username} onError='!?'/>
                    <h4 className="username">{me.username}</h4>

                    {context.notificationCount > 0 && 
                    <div className="notification-symbol">
                        <span>{context.notificationCount}</span>
                    </div>}
                </button>

                <span className="credits" onClick={() => Navigate('/orders')}>{me.credits} Credits</span>

                <button className="logout" onClick={logout}>Uitloggen</button>
            </div>
        );
    }

    return (
        <div className={'user anonymous' + (width < mobileWidth ? ' mobile': '')}>
            <button className="login" onClick={() => Navigate('/auth')}>Inloggen</button>
            <button className="signup" onClick={() => Navigate('/auth')}>Aanmelden</button>
        </div>
    );
}
