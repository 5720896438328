import React, { useState } from 'react';

import Fetch from '../../utils/fetchUtil';
import { Navigate } from '../../rensRouter/rensNavigate';
import Util from '../../utils/util';

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            Util.errorPopup('Uw Wachtwoorden komen niet overeen');
            return;
        }

        const token = Util.getPage().pathEnd;
        const result = await Fetch.resetPassword(token, password);
        if (result.ok) {
            Util.updatePopup('Wachtwoord is gewijzigd. U wordt over 5 seconden naar de login pagina gestuurd!');
            setTimeout(() => Navigate('/auth'), 5000);
        }
    };

    return (
        <div className="page reset-password">
            <div className="content">
                <h2>Uw Wachtwoord Resetten</h2>
                <form onSubmit={onSubmit}>
                    <input type="password" placeholder="Nieuw Wachtwoord" required minLength="8" maxLength="64"
                        value={password} onChange={(e) => setPassword(e.target.value)}
                    />
                    <input type="password" placeholder="Nieuw Wachtwoord Herhalen" required minLength="8" maxLength="64" 
                        value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    <input type="submit" value="Wachtwoord Resetten" />
                </form>
            </div>
        </div>
    )
}