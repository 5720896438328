
import React from 'react';
import { Wave } from 'react-css-spinners';

export default function Loading({ name, color, text }) {
    let className = 'loading';
    if (name) className += ` ${name}`;

    let spanText = text || 'Laden';

    return (
        <div className={className}>
            <span style={{ color }}>{spanText}</span>
            <Wave color={color || "#6b8ac2"} />
        </div>
    );
}
